import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  Reviews,
  CustomerReviewModal,
} from "@bluefin/components";
import { Header, Image, span } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>
                <Image
                  size={"small"}
                  src={
                    "https://fisherman.gumlet.io/public/5bf72e0e-0e45-45cb-9040-ed3e9a7feb51/stripes_1.png"
                  }
                />
                <span>About us</span>
              </Header>
            </Content.Markup>
            <Content.Markup
              width={16}
              isMarkdown={false}
              content={
                <div>
                  <h3>ONLINE BOOKING</h3>
                  <p>
                    Please note that we only offer Online Booking for our
                    Egleston Square location. Click our Book Now button to pick
                    an appointment!
                  </p>
                  <p>
                    If you would like to book our Mattapan Square location,
                    please call the shop at (617) 298-3551. We look forward to
                    serving you!
                  </p>
                  <h3>DO YOUR CLIPPERS NEED SOME ATTENTION?</h3>
                  <p>
                    We also offer clipper repair and blade sharpening! Come on
                    by.
                  </p>
                  <h3>OUR STORY</h3>
                  <p>
                    In the late 1950's Herman Lawson, one of the eldest of the
                    six Lawson brothers, was motivated to follow his uncle and
                    two aunts to Boston. His initial employment was with the
                    Boston City Hospital (currently BMC), but after working
                    there a few years he decided to go back to the trade he had
                    always loved in North Carolina – CUTTING HAIR! He joined
                    forces with John Tracey (a local barber) on Shawmut Avenue
                    and was able to complete his education to become a licensed
                    barber.
                  </p>
                  <p>
                    In 1962, after the owner became too ill to run the business,
                    (Herman, after meeting with his brothers who had now
                    migrated to Boston) decided to do something no other family
                    member had done, open his own business. With a wife and two
                    children, and the purchase of the business, Herman was on
                    his way.
                  </p>
                  <p>
                    With that one move, the Lawson Brothers success was on it
                    way and from there has escalated near and far. Upon seeing
                    what one brother could accomplish, the other brothers set
                    out to follow in his footsteps. The remaining five were
                    instrumental in joining together, then moving on separately,
                    to open their own shops. That success has penetrated deep
                    within the core of the family and other budding
                    entrepreneurs, businessmen and businesswomen emerge. Some
                    have academic degrees and others have "Degrees from the
                    School of Hard Knocks". Some are tops in their area and
                    journeymen in other occupations. The family has learned over
                    the years that it does not take a piece of paper for other
                    to know how good you are… that shows in the job you do and
                    the way you treat others.
                  </p>
                  <p className={"italic-text"}>
                    With that one move, the Lawson Brothers success was on it
                    way and from there has escalated near and far. Upon seeing
                    what one brother could accomplish, the other brothers set
                    out to follow in his footsteps. The remaining five were
                    instrumental in joining together, then moving on separately,
                    to open their own shops. That success has penetrated deep
                    within the core of the family and other budding
                    entrepreneurs, businessmen and businesswomen emerge. Some
                    have academic degrees and others have "Degrees from the
                    School of Hard Knocks". Some are tops in their area and
                    journeymen in other occupations. The family has learned over
                    the years that it does not take a piece of paper for other
                    to know how good you are… that shows in the job you do and
                    the way you treat others.
                  </p>
                </div>
              }
            />
          </Content>
          <div className={"reviews-container"}>
            <Header as={"h1"}>
              <Image
                size={"small"}
                src={
                  "https://fisherman.gumlet.io/public/5bf72e0e-0e45-45cb-9040-ed3e9a7feb51/stripes_1.png"
                }
              />
              <span>Customer Reviews</span>
            </Header>
            <Reviews
              reviews={fishermanBusiness.reviews}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={""}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={fishermanBusiness.type}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        author
        text
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
